import { z } from 'zod';

export const SortDirectionSchema = z.enum(['asc', 'desc']);

export const SortFieldSchema = z.object({
	direction: SortDirectionSchema,
	priority: z.number().optional(),
});

export const FieldComparisonSchema = z.object({
	name: z.string(),
	value1: z.any(),
	value2: z.any(),
	direction: SortDirectionSchema,
	priority: z.number(),
});
