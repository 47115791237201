import { z } from 'zod';

export const PriceSchema = z.object({
	pricePerMonth: z.number(),
	setupPrice: z.number(),
	initialMonthPrice: z.number(),
	currency: z.string(),
});

export const InfobipNumberSchema = z.object({
	numberKey: z.string(),
	number: z.string(),
	country: z.string(),
	type: z.string(),
	capabilities: z.array(z.string()),
	shared: z.boolean(),
	price: PriceSchema,
});

export const InfobipNumbersSchema = z.object({
	numbers: z.array(InfobipNumberSchema),
});

export const InfoBipNumberPreviewSchema = z.object({
	numberKey: z.string(),
	number: z.string(),
	countryCode: z.string(),
});
