import { z } from 'zod';
import { MailingAddressSchema } from './geo';

export const TaxSchema = z.object({
	name: z.string().optional(),
	amount: z.number().optional(),
});

export const CheckoutSchema = z.object({
	dcID: z.string().optional(), // DispenseCheckoutID
	ept: z.string().optional(), // EcommPickupType
	ag: z.boolean().optional(), // AppGenerated
});

export const StandardCategorySchema = z.object({
	sub: z.string(),
	parent: z.string(),
	bevAlcParent: z.string().optional(),
});

export const OrderSchema = z.object({
	brandIDs: z.array(z.string()),
	brandData: z.array(z.string()),
	coupon: z.string().optional(),
	productId: z.string().optional(),
	category: z.string().optional(),
	currency: z.string().optional(),
	name: z.string().optional(),
	brand: z.string().optional(),
	variant: z.string().optional(),
	imageUrl: z.string().optional(),
	shopUrl: z.string().optional(),
	url: z.string().optional(),
	sku: z.string().optional(),
	storeName: z.string().optional(),
	parentCategory: z.string().nullable().optional(),
	prBrand: z.string().nullable().optional(),
	prCat: z.string().optional(),
	customAttributes: z.array(z.string()),
	quantityFloat: z.number().optional(),
	totalDiscount: z.number().optional(),
	totalPrice: z.number().optional(),
	totalTax: z.number().optional(),
	quantity: z.number().optional(),
	timestamp: z.number().optional(),
	totalCost: z.number().optional(),
	review: z.number().nullable().optional(),
});

export const ProductSchema = z.object({
	sku: z.string(),
	productId: z.string(),
	category: z.string(),
	name: z.string(),
	brand: z.string(),
	variant: z.string(),
	url: z.string(),
	imageUrl: z.string(),
	totalCost: z.number(),
});

export const SaleSchema = z.object({
	addr: MailingAddressSchema.optional(),
	paymentMethod: z.string().optional(),
	platform: z.string().optional(),
	storeName: z.string().optional(),
	srcID: z.string().optional(),
	employeeID: z.string().optional(),
	employeeName: z.string().optional(),
	contactID: z.string().optional(),
	receiptID: z.string().optional(),
	source: z.string().nullable(),
	discount: z.string().nullable(),
	altReceipt: z.string().nullable(),
	taxes: z.array(TaxSchema).optional(),
	orders: z.array(OrderSchema).optional(),
	storeID: z.number().nullable(),
	customerType: z.number().nullable(),
	tableName: z.string().nullable(),
	tableNo: z.number().nullable(),
	inserted: z.number().optional(),
	vic: z.array(z.string()),
	deliveryOrder: z.boolean().optional(),
	medicalOrder: z.boolean().optional(),
	ocrMatchSuccessID: z.string().optional(),
});
