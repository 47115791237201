import { z } from 'zod';

export const TextingImageContentScanResultsSchema = z.object({
	content: z.string(),
	flaggedLikelihood: z.number(),
	flaggedIndicators: z.array(z.string()),
	adLikelihood: z.number(),
	cannabisRelatedLikelihood: z.number(),
	cannabisRelatedIndicators: z.array(z.string()),
	cannabisPromotionLikelihood: z.number(),
	cannabisPromotionIndicators: z.array(z.string()),
	logoLikelihood: z.number(),
	logoIndicators: z.array(z.string()),
});
