import { z } from 'zod';

export const PixelPopupWidgetDisplayFrequencyUnitSchema = z.enum(['second', 'minute', 'hour', 'day', 'week', 'month', 'year']);
export const PixelPopupWidgetTriggerSchemaType = z.enum(['onLoad', 'onClick', 'onScroll', 'onExit']);
export const PixelPopupWidgetTargetingConditionSchema = z.enum(['equals', 'notEquals', 'contains', 'notContains']);

export const PixelPopupWidgetTriggerSchema = z.object({
	threshold: z.number().optional(),
	actionDelay: z.number().optional(),
});

export const PixelPopupWidgetTargetSchema = z.object({
	id: z.string().optional(),
	condition: PixelPopupWidgetTargetingConditionSchema.optional(),
	value: z.string().optional(),
});

export const PixelPopupWidgetTargetsSchema = z.object({
	includeURLs: z.array(PixelPopupWidgetTargetSchema).optional(),
	excludeURLs: z.array(PixelPopupWidgetTargetSchema).optional(),
});

export const PixelPopupWidgetDisplayFrequencySchema = z.object({
	count: z.number().optional(),
	unit: PixelPopupWidgetDisplayFrequencyUnitSchema.optional(),
});

export const PixelPopupWidgetSchema = z.object({
	enabled: z.boolean(),
	triggers: z.record(PixelPopupWidgetTriggerSchemaType, PixelPopupWidgetTriggerSchema).optional(),
	displayFrequency: PixelPopupWidgetDisplayFrequencySchema.optional(),
	targets: PixelPopupWidgetTargetsSchema.optional(),
});
