import { z } from 'zod';

export const MessageSchema = z.object({
	to: z.string().optional(),
	twilioStatus: z.string().optional(),
	body: z.string().optional(),
	contactID: z.string().optional(),
	errorMsg: z.string().optional(),
	direction: z.string().optional(),
	cmpID: z.string().optional(),
	meta: z.string().optional(),
	from: z.string().optional(),
	SID: z.string().optional(),
	price: z.string().nullable().optional(),
	userID: z.string().nullable().optional(),
	platform: z.string().nullable().optional(),
	discountTemplate: z.string().nullable().optional(),
	carrier: z.string().nullable().optional(),
	mediaURL: z.string().nullable().optional(),
	title: z.string().nullable().optional(),
	publisherUserID: z.string().nullable().optional(),
	brandBillingID: z.string().nullable().optional(),
	hungup: z.string().nullable().optional(),
	segments: z.number().nullable().optional(),
	duration: z.number().nullable().optional(),
	errorCode: z.number().optional(),
	timestamp: z.number().optional(),
	isBrowser: z.boolean().nullable().optional(),
	isEmail: z.boolean().nullable().optional(),
	isPush: z.boolean().nullable().optional(),
	isAd: z.boolean().nullable().optional(),
	thirdParty: z.boolean().nullable().optional(),
	snailMail: z.boolean().nullable().optional(),
	voice: z.boolean().nullable().optional(),
	trs: z.boolean().nullable().optional(),
	sent: z.boolean().optional(),
	isMMS: z.boolean().optional(),
});

export const MessageOutSchema = MessageSchema.extend({
	mediaURL: z.string(),
	contentType: z.string(),
	unshorty: z.string(),
	msgOpen: z.number(),
});
