import { z } from 'zod';
import { SaleSchema } from './sale';
import { SortFieldSchema } from './general';

export const LoyaltyPointAdjustSchema = z.object({
	note: z.string().optional(),
	email: z.string().optional(),
	campaignID: z.string().optional(),
	value: z.number().optional(),
	ts: z.number().optional(),
});

export const LoyaltyReferralSchema = z.object({
	accrual: z.number().optional(),
	start: z.number().optional(),
	optionalOptIn: z.boolean().optional(),
});

export const CollectibleEventSchema = z.object({
	id: z.string(),
	product_name: z.string(),
	batch_num: z.string(),
});

export const PointExpirationSchema = z.object({
	fromReceipt: z.string(),
	fromDate: z.number(),
});

export const ViewableRedemptionSchema = z.object({
	discountID: z.string(),
	discountName: z.string(),
	ts: z.number(),
	pointsDeduction: z.number(),
	txID: z.string().optional(), // Transaction ID
});

export const PointFreezeWindowSchema = z.object({
	id: z.number().optional(),
	user_id: z.string().optional(),
	pii: z.string().optional(),
	source: z.string().optional(),
	start_time: z.string().optional(),
	end_time: z.string().optional(),
	created: z.string().optional(),
	updated: z.string().optional(),
	reset_points: z.boolean().optional(),
	reset_gaps: z.boolean().optional(),
});

export const PointFreezeWindowBulkSchema = z.object({
	contact_id: z.string().optional(),
	phone: z.string().optional(),
	email: z.string().optional(),
	start_time: z.string().optional(),
	end_time: z.string().optional(),
	reset_points: z.boolean().optional(),
	reset_gaps: z.boolean().optional(),
});

export const LoyaltyBalanceSchema = z.object({
	srcID: z.string().optional(),
	balanceType: z.string().optional(),
	source: z.string().optional(),
	companyID: z.string().nullable().optional(),
	balance: z.number().optional(),
	ts: z.number().optional(),
	mirror: z.boolean().nullable().optional(),
	fileUpload: z.number().nullable().optional(),
});

export const PointsEventTypeSchema = z.enum([
	'sale',
	'adjustment',
	'redemption',
	'expiration',
	'legacy',
	'collectible',
	'point_freeze_start',
	'point_freeze_end',
	'referral',
	'boost',
	'visit',
]);

export const PointEventSchema = z.object({
	adjustment: LoyaltyPointAdjustSchema.optional(),
	redemption: ViewableRedemptionSchema.optional(),
	expiration: PointExpirationSchema.optional(),
	legacy: LoyaltyBalanceSchema.optional(),
	sale: SaleSchema.optional(),
	collectible: CollectibleEventSchema.optional(),
	pointFreezeWindow: PointFreezeWindowSchema.optional(),
	type: PointsEventTypeSchema,
	fromReceipt: z.string(),
	expirationTS: z.number(),
	timestamp: z.number(),
	value: z.number(),
	saleAmount: z.number(),
});

export const PointsTimelineSchema = z.object({
	events: z.array(PointEventSchema),
	points: z.number(),
	memberSaleAmount: z.number(),
	redeemed: z.number(),
	expired: z.number(),
	adjustments: z.number(),
	modifiers: z.number(),
	accrued: z.number(),
	visits: z.number(),
	hidePoints: z.boolean(),
	showProduct: z.boolean(),
	contactID: z.string(),
	favStoreID: z.number(),
	// Start time of earliest open manual point freeze window. If 0, there is no open window
	manualFreezeSince: z.number(),
	// Start time of earliest open opt-out point freeze window. If 0, there is no open window
	optOutFreezeSince: z.number(),
});

export const AddressOptionsSchema = z.object({
	city: z.number().optional(),
	country: z.number().optional(),
	zipCode: z.number().optional(),
	state: z.number().optional(),
	street: z.number().optional(),
});

export const StyleSchema = z.object({
	config: z.any().optional(),
	app: z.any().optional(),
	css: z.any().optional(),
	data: z.any().optional(),
	fonts: z.any().optional(),
	icons: z.any().optional(),
});

export const CustomInputSchema = z.object({
	id: z.string().optional(),
	type: z.string().optional(),
	name: z.string().optional(),
	label: z.string().optional(),
	placeholder: z.string().optional(),
	options: z.array(z.unknown()).optional(),
	disabled: z.boolean().optional(),
	required: z.boolean().optional(),
	priority: z.number().int().optional(),
});

export const OptInsForLoyaltySchema = z.object({
	start: z.number().int(),
	browser: z.boolean(),
	email: z.boolean(),
	push: z.boolean(),
	text: z.boolean(),
	voice: z.boolean(),
	allRequired: z.boolean(),
	resetPoints: z.boolean(),
});

export const TierSchema = z.object({
	points: z.number(),
	discount: z.number(),
	max: z.number(),
});

export const LoyaltyTemplateSchema = z.object({
	name: z.string().optional(),
	doubleOptIn: z.boolean().nullable().optional(),
	avatar: z.string().optional(),
	userID: z.string().optional(),
	userName: z.string().optional(),
	pageTitle: z.string().optional(),
	pageBody: z.string().optional(),
	consentText: z.string().optional(),
	signupTitle: z.string().optional(),
	thanksTitle: z.string().optional(),
	thanksText: z.string().optional(),
	redirectURL: z.string(),
	erptInterval: z.string().optional(),
	id: z.string().optional(),
	tiers: z.array(TierSchema).optional(),
	erpts: z.array(z.string()).optional(),
	style: StyleSchema.optional(),
	customInputs: z.array(CustomInputSchema).optional(),
	expireAll: z.boolean().optional(),
	expiration: z.number().optional(),
	expirationStart: z.number().int().optional(),
	maxVisitsPerDay: z.number().optional(),
	accessoriesOnly: z.number().int().optional(),
	excludeTaxDollarCutoff: z.number().int().optional(),
	updated: z.number().int().optional(),
	erptTime: z.number().int().optional(),
	accrual: z.number().optional(),
	created: z.number().int().optional(),
	legacyCreated: z.number().int().optional(),
	perVisit: z.number().optional(),
	hidePointsForMed: z.boolean().optional(),
	hidePointsForRec: z.boolean().optional(),
	hidePointsForSMSNonOpt: z.boolean().optional(),
	hidePointsForEmailNonOpt: z.boolean().optional(),
	hidePointsForPushNonOpt: z.boolean().optional(),
	depleteViaDiscount: z.boolean().optional(),
	hideFavStores: z.boolean().optional(),
	enablePin: z.boolean().optional(),
	lastName: z.number().optional(),
	driversLicense: z.number().optional(),
	medicalID: z.number().optional(),
	medIDExpiration: z.number().optional(),
	signatureRequired: z.number().optional(),
	forceAddress: z.boolean().optional(),
	address: z.number().optional(),
	addressOptions: AddressOptionsSchema.optional(),
	perURLSettings: z.boolean().optional(),
	allowBudtenderEdits: z.boolean().optional(),
	collectibles: z.number().optional(),
	budtenderCantSeeWallet: z.boolean().optional(),
	budtenderCantSeeQueue: z.boolean().optional(),
	disableEmail: z.boolean().optional(),
	disable2FA: z.boolean().optional(),
	implicitOptIn: z.boolean().optional(),
	sendEmailOptIn: z.boolean().optional(),
	hideWalletURL: z.boolean().optional(),
	useCaptcha: z.boolean().optional(),
	birthDate: z.number().optional(),
	medRec: z.number().optional(),
	email: z.number().optional(),
	firstName: z.number().optional(),
	phone: z.number().optional(),
	referral: LoyaltyReferralSchema.optional(),
	showToReferral: z.boolean(),
	archived: z.boolean().optional(),
	rounding: z.number(),
	emailReportPersonas: z.boolean(),
	reset: z.number().int().optional(),
	fifo: z.boolean().optional(),
	version: z.string().optional(),
	defaultStores: z.array(z.number().int()).optional(),
	optInsForLoyalty: OptInsForLoyaltySchema.optional(),
	upgraded: z.number().optional(),
	upgradeBy: z.string().optional(),
	newSignupFormID: z.string().optional(),
});

export const LoyaltyTemplateSortFieldsSchema = z.object({
	id: SortFieldSchema.optional(),
	name: SortFieldSchema.optional(),
	views: SortFieldSchema.optional(),
	submissions: SortFieldSchema.optional(),
	created: SortFieldSchema.optional(),
	updated: SortFieldSchema.optional(),
});
