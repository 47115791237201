import { z } from 'zod';

export const InventorySchema = z.object({
	sku: z.string(),
	productId: z.string(),
	category: z.string(),
	parentCategory: z.string(),
	name: z.string(),
	brand: z.string(),
	variant: z.string(),
	barcode: z.string(),
	url: z.string(),
	imageUrl: z.string(),
	description: z.string(),
	supplierName: z.string(),
	currency: z.string(),
	customAttributes: z.array(z.string()),
	supplierId: z.number(),
	totalTax: z.number(),
	dateUpdated: z.number(),
	MSRP: z.number(),
	quantity: z.number(),
	thc: z.number(),
	cbd: z.number(),
	dateAdded: z.number(),
	salesPrice: z.number(),
	inStock: z.boolean(),
	isSaleable: z.boolean(),
	isArchived: z.boolean(),
});

export const AttributeSchema = z.object({
	parent: z.string().optional(),
	key: z.string(),
	value: z.string(),
	contactID: z.string().optional(),
	ts: z.number().optional(),
	src: z.string().optional(),
});

export const PendingAttributeSchema = AttributeSchema.extend({
	pending: z.boolean().optional(),
});
