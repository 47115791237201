import { z } from 'zod';

/**
 * Enums and Constants
 */

export const BrandThemeSchema = z.enum(['light', 'dark']);
export const PrizeWheelDisplayPeriodSchema = z.enum(['Days', 'Weeks', 'Months']);
export const LandingVariantSchema = z.enum(['modern', 'linkTree']);
export const LandingPageButtonLinkSchema = z.enum(['shopOnline', 'directions', 'callUs', 'memberWallet']);
export const IdentificationMethodSchema = z.enum(['age-gate', 'multiple-choice']);
export const SignupFormFieldOptionsSchema = z.enum(['firstName', 'lastName', 'email', 'phone', 'address', 'dob', 'favStore', 'signature', 'ageGate']);

/**
 * Basic Types
 */

export const PaddingTypeSchema = z.object({
	top: z.number().optional(),
	right: z.number().optional(),
	bottom: z.number().optional(),
	left: z.number().optional(),
});

export const BorderRadiusTypeSchema = z.object({
	topLeft: z.number().optional(),
	topRight: z.number().optional(),
	bottomLeft: z.number().optional(),
	bottomRight: z.number().optional(),
});

export const ContainerStylesSchema = z.object({
	display: z.string().optional(),
	flexDir: z.string().optional(),
	justifyContent: z.string().optional(),
	alignItems: z.string().optional(),
	textAlign: z.string().optional(),
	padding: PaddingTypeSchema.optional(),
	margin: PaddingTypeSchema.optional(),
	borderRadius: BorderRadiusTypeSchema.optional(),
	bg: z.string().optional(),
	bgHover: z.string().optional(),
	borderColor: z.string().optional(),
	borderColorHover: z.string().optional(),
	backgroundImage: z.string().optional(),
});

export const TextStylesSchema = z.object({
	color: z.string().optional(),
	colorHover: z.string().optional(),
	fontWeight: z.string().optional(),
	fontSize: z.number().optional(),
	fontFamily: z.string().optional(),
});

export const TextContainerSchema = z.object({
	...ContainerStylesSchema.shape,
	...TextStylesSchema.shape,
	textDefault: z.string().optional(),
	textAlt: z.string().optional(),
	textSuccess: z.string().optional(),
	textLoading: z.string().optional(),
});

/**
 * Style Types
 */

export const InputStyleSchema = z.object({
	...TextContainerSchema.shape,
	placeholder: z.string().optional(),
	selected: TextContainerSchema.optional(),
	disabled: TextContainerSchema.optional(),
});

export const ButtonStyleSchema = z.object({
	...TextContainerSchema.shape,
	height: z.string().optional(),
	width: z.string().optional(),
	selected: TextContainerSchema.optional(),
	disabled: TextContainerSchema.optional(),
});

export const BrandColorPalletSchema = z.object({
	brandColor: z.string().optional(),
	secondaryColor: z.string().optional(),
	specialColor: z.string().optional(),
	linkColor: z.string().optional(),
	successColor: z.string().optional(),
	warningColor: z.string().optional(),
	dangerColor: z.string().optional(),
	textBrand: z.string().optional(),
	textPrimary: z.string().optional(),
	textSecondary: z.string().optional(),
	surfacePrimary: z.string().optional(),
	surfaceSecondary: z.string().optional(),
	borderPrimary: z.string().optional(),
});

export const BrandControlStylesSchema = z.object({
	input: InputStyleSchema.optional(),
	buttonPrimary: ButtonStyleSchema.optional(),
	buttonSecondary: ButtonStyleSchema.optional(),
	buttonSpecial: ButtonStyleSchema.optional(),
});

export const BrandStyleSchema = z.object({
	...BrandColorPalletSchema.shape,
	...TextStylesSchema.shape,
	...BrandControlStylesSchema.shape,
});

export const BrandLogosSchema = z.object({
	logo: z.string().optional(),
	squareLogo: z.string().optional(),
	logoPlacement: z.enum(['onCover', 'belowCover']).optional(),
	fullLogo: z.string().optional(),
	coverBackground: z.string().optional(),
	mmsLogo: z.string().optional(),
	optInLogo: z.string().optional(),
});

/**
 * App Store Info
 */

export const AppleAppStoreInfoSchema = z.object({
	appName: z.string().optional(),
	appSubtitle: z.string().optional(),
	appDescription: z.string().optional(),
	appKeywords: z.string().optional(),
	appIcon: z.string().nullable().optional(),
	appScreenshots: z.array(z.string()).optional(),
});

export const GooglePlayStoreInfoSchema = z.object({
	appName: z.string().optional(),
	appSubtitle: z.string().optional(),
	appDescription: z.string().optional(),
	appKeywords: z.string().optional(),
	appIcon: z.string().optional(),
	appScreenshots: z.array(z.string()).optional(),
});

export const AppStoreInfoSchema = z.object({
	appleInfo: AppleAppStoreInfoSchema.optional(),
	googleInfo: GooglePlayStoreInfoSchema.optional(),
	termsAndConditionsUrl: z.string().optional(),
	privacyPolicyUrl: z.string().optional(),
	eulaUrl: z.string().optional(),
	consentText: z.string().optional(),
});

/**
 * App Images
 */

export const AppImagesSchema = z.object({
	...BrandLogosSchema.shape,
	splashLoading: z.string().optional(),
	splashFallback: z.string().optional(),
});

/**
 * App Settings
 */

export const OrdersTabSettingsSchema = z.object({
	enabled: z.boolean().optional(),
	showOrderHistoryFeed: z.boolean().optional(),
});

export const ShopTabSettingsSchema = z.object({
	enabled: z.boolean().optional(),
	shopUrl: z.string().optional(),
	enableAndroid: z.boolean().optional(),
	forceRedirect: z.boolean().optional(),
	forceRedirectInAppOnly: z.boolean().optional(),
});

export const UpdatesTabSettingsSchema = z.object({
	enabled: z.boolean().optional(),
	showEmailsInFeed: z.boolean().optional(),
	hideMessagesBefore: z.number().optional(),
});

export const DiscoverTabSettingsSchema = z.object({
	enabled: z.boolean().optional(),
	discoverUrl: z.string().optional(),
	appleDiscoverUrl: z.string().optional(),
	googleDiscoverUrl: z.string().optional(),
	enableAndroid: z.boolean().optional(),
	forceRedirect: z.boolean().optional(),
	forceRedirectInAppOnly: z.boolean().optional(),
});

export const AppSignupFieldSchema = z.object({
	enabled: z.boolean().optional(),
	required: z.boolean().optional(),
	field: z.string(),
	placeholder: z.string().optional(),
});

export const AppFeaturesGeneralSchema = z.object({
	signupFields: z.record(SignupFormFieldOptionsSchema, AppSignupFieldSchema).optional(),
	forceSignupAfterLogin: z.boolean().optional(),
	browserNotificationOptIn: z.boolean().optional(),
	promptAddToHomeScreen: z.boolean().optional(),
	allowScanToPay: z.boolean().optional(),
});

export const AppFeaturesStoreSchema = z.object({
	enableStoreDropdown: z.boolean().optional(),
	allowChangeStore: z.boolean().optional(),
	showDirections: z.boolean().optional(),
	showAddress: z.boolean().optional(),
	showCallStore: z.boolean().optional(),
});

export const AppFeaturesSharingSchema = z.object({
	showRaf: z.boolean().optional(),
	showShareInMenu: z.boolean().optional(),
});

export const AppFeaturesProductFeedSchema = z.object({
	enabled: z.boolean().optional(),
	showOnHomeTab: z.boolean().optional(),
	showOnShopTab: z.boolean().optional(),
	numberOfProducts: z.number().optional(),
});

export const AppFeaturesPointsAndDiscountsSchema = z.object({
	enablePoints: z.boolean().optional(),
	enableDiscounts: z.boolean().optional(),
	showRedeemDiscounts: z.boolean().optional(),
});

export const AppFeaturesProfileSchema = z.object({
	showBirthday: z.boolean().optional(),
	showAddress: z.boolean().optional(),
});

export const AppPrizeWheelFrequencySchema = z.object({
	frequency: z.number().int().optional(),
	unit: PrizeWheelDisplayPeriodSchema.optional(),
	start: z.number().int().optional(),
});

export const AppPrizeWheelStyleSchema = z.object({
	primaryColor: z.string().optional(),
	secondaryColor: z.string().optional(),
	notchColor: z.string().optional(),
});

export const AppFeaturesPrizeWheelSchema = z.object({
	enabled: z.boolean().optional(),
	wheelFrequency: AppPrizeWheelFrequencySchema.optional(),
	style: AppPrizeWheelStyleSchema.optional(),
});

export const AppFeaturesSchema = z.object({
	general: AppFeaturesGeneralSchema.optional(),
	store: AppFeaturesStoreSchema.optional(),
	sharing: AppFeaturesSharingSchema.optional(),
	productFeed: AppFeaturesProductFeedSchema.optional(),
	pointsAndDiscounts: AppFeaturesPointsAndDiscountsSchema.optional(),
	profile: AppFeaturesProfileSchema.optional(),
	prizeWheel: AppFeaturesPrizeWheelSchema.optional(),
});

/**
 * App FAQ
 */

export const AppFAQSchema = z.object({
	priority: z.number(),
	question: z.string(),
	answer: z.string(),
});

/**
 * App Verbiage
 */

export const AppTabsVerbiageSchema = z.object({
	home: z.string().optional(),
	shop: z.string().optional(),
	orders: z.string().optional(),
	updates: z.string().optional(),
	discover: z.string().optional(),
});

export const AppHamburgerMenuVerbiageSchema = z.object({
	profileName: z.string().optional(),
	faqName: z.string().optional(),
	shareAppName: z.string().optional(),
	collectiblesName: z.string().optional(),
	scanToPayName: z.string().optional(),
	convertToGiftCardName: z.string().optional(),
	receiptScanName: z.string().optional(),
});

export const AppNavigationVerbiageSchema = z.object({
	tabs: AppTabsVerbiageSchema.optional(),
	hamburgerMenu: AppHamburgerMenuVerbiageSchema.optional(),
});

export const GeneralVerbiageSchema = z.object({
	points: z.string().optional(),
});

export const AppHomeTabsVerbiageSchema = z.object({
	recentPurchases: z.string().optional(),
	popularProducts: z.string().optional(),
});

export const AppHomeVerbiageSchema = z.object({
	tabs: AppHomeTabsVerbiageSchema.optional(),
});

export const AppShopTabsVerbiageSchema = z.object({
	popularProducts: z.string().optional(),
	allProducts: z.string().optional(),
});

export const AppShopVerbiageSchema = z.object({
	tabs: AppShopTabsVerbiageSchema.optional(),
});

export const AppRAFVerbiageSchema = z.object({
	header: z.string().optional(),
	description: z.string().optional(),
	shareText: z.string().optional(),
});

export const AppReviewVerbiageSchema = z.object({
	header: z.string().optional(),
	description: z.string().optional(),
});

export const AppSocialVerbiageSchema = z.object({
	raf: AppRAFVerbiageSchema.optional(),
	review: AppReviewVerbiageSchema.optional(),
});

export const AppDiscountsVerbiageSchema = z.object({
	redeemAtSalesCounterText: z.string().optional(),
	redeemInStoreText: z.string().optional(),
	redeemButtonText: z.string().optional(),
});

export const AppVerbiageSchema = z.object({
	general: GeneralVerbiageSchema.optional(),
	navigation: AppNavigationVerbiageSchema.optional(),
	home: AppHomeVerbiageSchema.optional(),
	shop: AppShopVerbiageSchema.optional(),
	social: AppSocialVerbiageSchema.optional(),
	discounts: AppDiscountsVerbiageSchema.optional(),
});

/**
 * Landing Page
 */

export const LandingPageButtonLinkSettingsSchema = z.object({
	text: z.string().optional(),
	url: z.string().optional(),
	enabled: z.boolean().optional(),
});

export const LandingPageSettingsSchema = z.object({
	variant: LandingVariantSchema.optional(),
	captchaMethod: IdentificationMethodSchema.optional(),
	buttonsMap: z.record(LandingPageButtonLinkSchema, LandingPageButtonLinkSettingsSchema).optional(),
	style: BrandStyleSchema.optional(),
});

/**
 * Captcha Page
 */

export const CaptchaPageMethodSchema = z.object({
	header: TextContainerSchema.optional(),
	button: ButtonStyleSchema.optional(),
	enabled: z.boolean().optional(),
});

export const CaptchaPageMethodsSchema = z.object({
	pin: CaptchaPageMethodSchema.optional(),
	dob: CaptchaPageMethodSchema.optional(),
	phone: CaptchaPageMethodSchema.optional(),
});

export const CaptchaPageSettingsSchema = z.object({
	methods: CaptchaPageMethodsSchema.optional(),
	style: BrandStyleSchema.optional(),
});

/**
 * App Settings
 */

export const AppSettingsSchema = z.object({
	version: z.string().optional(),
	appInfo: AppStoreInfoSchema.optional(),
	theme: BrandThemeSchema.optional(),
	images: AppImagesSchema.optional(),
	style: BrandStyleSchema.optional(),
	ordersTab: OrdersTabSettingsSchema.optional(),
	shopTab: ShopTabSettingsSchema.optional(),
	updatesTab: UpdatesTabSettingsSchema.optional(),
	discoverTab: DiscoverTabSettingsSchema.optional(),
	general: AppFeaturesGeneralSchema.optional(),
	store: AppFeaturesStoreSchema.optional(),
	sharing: AppFeaturesSharingSchema.optional(),
	productFeed: AppFeaturesProductFeedSchema.optional(),
	pointsAndDiscounts: AppFeaturesPointsAndDiscountsSchema.optional(),
	profile: AppFeaturesProfileSchema.optional(),
	prizeWheel: AppFeaturesPrizeWheelSchema.optional(),
	verbiage: AppVerbiageSchema.optional(),
	faqs: z.array(AppFAQSchema).optional(),
	lastUpdated: z.number().optional(),
});

/**
 * User Settings
 */

export const UserSettingsSchema = z.object({
	uid: z.string().optional(),
	storeID: z.string().optional(),
	version: z.number().optional(),
	images: BrandLogosSchema.optional(),
	style: BrandStyleSchema.optional(),
	appSettings: AppSettingsSchema.optional(),
	landingPage: LandingPageSettingsSchema.optional(),
	captchaPage: CaptchaPageSettingsSchema.optional(),
	merged: z.boolean().optional(),
});
