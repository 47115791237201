import { z } from 'zod';
import { MailingAddressSchema } from './geo';
import { IncomingPhoneNumberSchema } from './twilio';

export const StoreReviewTemplateSchema = z.object({
	google: z.string(),
	facebook: z.string(),
	facebookPageID: z.string(),
	leafly: z.string(),
	weedmaps: z.string(),
	instagram: z.string(),
	twitter: z.string(),
	yelp: z.string(),
	googlePlay: z.string(),
	appleStore: z.string(),
});

export const StoreSchema = z.object({
	addr: MailingAddressSchema.nullable().optional(),
	signupNumber: IncomingPhoneNumberSchema.nullable().optional(),
	sourceMapping: z.record(z.array(z.string())),
	tollFree: IncomingPhoneNumberSchema.nullable().optional(),
	reviewLinks: StoreReviewTemplateSchema,
	notes: z.string().optional(),
	avatar: z.string().optional(),
	userID: z.string().optional(),
	globalID: z.string().optional(),
	venueID: z.string().optional(),
	nickname: z.string().optional(),
	domain: z.string().optional(),
	onlineShopURL: z.string().optional(),
	customURL: z.string().optional(),
	customURLAndroid: z.string().optional(),
	embeddedURL: z.string().optional(),
	embeddedMedURL: z.string().optional(),
	phone: z.string().optional(),
	name: z.string().optional(),
	telnyxSignup: z.string().optional(),
	voiceNumber: z.string().optional(),
	email: z.string().optional(),
	disclaimer: z.string().optional(),
	scraperMapping: z.string().optional(),
	rating: z.number().optional(),
	ranking: z.number().optional(),
	id: z.number().optional(),
	created_at: z.number().optional(),
	signupViaPhone: z.boolean().optional(),
	optInQueue: z.boolean().optional(),
	llOptInQueue: z.boolean().optional(),
	alleavesOptInQueue: z.boolean().optional(),
	meadowOptInQueue: z.boolean().optional(),
	archived: z.boolean().optional(),
	isBrandStore: z.boolean().optional(),
	hiddenFromForms: z.boolean().optional(),
	dynamicContentCustomURL: z.boolean().optional(),
});
