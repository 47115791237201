import { z } from 'zod';

export const ReviewTemplateSchema = z.object({
	id: z.string(),
	userID: z.string(),
	avatar: z.string(),
	userName: z.string(),
	name: z.string(),
	pageTitle: z.string().optional(),
	pageBody: z.string().optional(),
	google: z.string(),
	facebook: z.string(),
	yelp: z.string(),
	leafly: z.string(),
	weedmaps: z.string(),
	instagram: z.string(),
	tattle: z.string(),
	twitter: z.string(),
	googlePlay: z.string(),
	appleStore: z.string(),
	googleURL: z.string(),
	facebookURL: z.string(),
	facebookPageID: z.string(),
	facebookAppURL: z.string(),
	yelpURL: z.string(),
	leaflyURL: z.string(),
	weedmapsURL: z.string(),
	instagramURL: z.string(),
	tattleURL: z.string(),
	twitterURL: z.string(),
	googlePlayURL: z.string(),
	appleStoreURL: z.string(),
	created: z.number(),
	updated: z.number(),
	isGDelivery: z.boolean().optional(),
	isWMDelivery: z.boolean().optional(),
	isActive: z.boolean().optional(),
	archived: z.boolean().optional(),
	storeID: z.number().optional(),
	enableLogo: z.boolean().optional(),
	enableCoverPhoto: z.boolean().optional(),
	fullLogo: z.string().optional(),
	coverBackground: z.string().optional(),
	logo: z.string().optional(),
	logoOption: z.enum(['full-logo', 'avatar']),
});
