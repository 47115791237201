import { z } from 'zod';

export const MailingAddressSchema = z.object({
	city: z.string(),
	country: z.string(),
	zipCode: z.string(),
	state: z.string(),
	street: z.string(),
	raw: z.string().nullable(),
	lat: z.number(),
	long: z.number(),
});
