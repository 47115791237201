import { z } from 'zod';
import { RawContactSchema } from './contact';

export const RawFilesMetaSchema = z.object({
	createdBy: z.string(),
	desc: z.string(),
});

export const baseSchema = z.object({
	mid: z.number().optional(),
	optinApproved: z.bigint().optional(),
	loyaltyApproved: z.bigint().optional(),
	status: z.boolean().optional(),
	ageGate: z.boolean().optional(),
	lastPull: z.boolean().optional(),
});

export const RawFilesSchema = baseSchema.extend({
	files: z.array(z.string()).optional(),
	fileMetadata: z.record(RawFilesMetaSchema).optional(),
	fileAudiences: z.record(z.string()).optional(),
	fieldMapping: z.record(z.string()).optional(),
	macroMapping: z.record(z.string()).optional(),
});

export const BulkImportPesonasMetaSchema = RawFilesSchema.extend({
	fname: z
		.string()
		.min(3, { message: 'File name must be at least 3 characters' })
		.max(100, { message: 'File name must be at most 100 characters' })
		.regex(/^[a-zA-Z0-9\s]+$/, { message: 'File name must contain only letters, numbers, and spaces' }),
	fdesc: z.string().optional(),
	fuploader: z.string().optional(),
	allEmailOptins: z.boolean().optional(),
	allTextOptins: z.boolean().optional(),
	agreeTerms: z.boolean().refine((value) => value, { message: 'You must agree to the terms' }),
	personas: z.array(RawContactSchema).optional(),
});
