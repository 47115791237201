import { z } from 'zod';
import { SaleSchema } from './sale';

export const ActionType = z.enum([
	'text_received',
	'text_click_to_open',
	'text_sent',
	'text_click',
	'email_received',
	'email_open',
	'push_received',
	'push_open',
	'ad_received',
	'browser_received',
	'direct_mail_received',
	'TerpliReview',
	'TerpliRecommendation',
	'TerpliReviewDiscount',
	'TerpliRecommendationDiscount',
	'call_answer',
	'call_vm',
	'call_complete',
	'discount_redemption',
]);

export const ActionSchema = z.object({
	label: ActionType,
	platform: z.string(),
	cmpID: z.string().optional(),
	ltID: z.string().optional(),
	discID: z.string().nullable(),
	timestamp: z.number(),
});

export const ConversionSchema = z.object({
	action: ActionSchema.optional(),
	sale: SaleSchema.optional(),
	ID: z.string().optional(),
	contactID: z.string().optional(),
	srcID: z.string().optional(),
	platform: z.string(),
	inserted: z.number().optional(),
});
