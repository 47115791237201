import { z } from 'zod';
import { BrandLogosSchema, BrandStyleSchema, ContainerStylesSchema } from './user-settings';
import { PixelPopupWidgetSchema } from './pixel';

export const SurveyTypeSchema = z.enum(['customer', 'crew']);

export const SurveyQuestionTypeSchema = z.enum([
	'input',
	'textarea',
	'select',
	'date',
	'radio',
	'radio-vertical',
	'toggle',
	'toggle-vertical',
	'checkbox',
	'email',
	'phone',
	'multi-select',
	'number',
	'slider',
	'rating',
]);

export const SurveyVisitSchema = z.object({
	uid: z.string(),
	contactID: z.string(),
	surveyID: z.string(),
});

export const SurveyQuestionSchema = z.object({
	id: z.string().optional(),
	label: z.string().optional(),
	property: z.string().optional(),
	placeholder: z.string().optional(),
	value: z.union([z.string(), z.number(), z.boolean(), z.array(z.string())]).optional(),
	description: z.string().optional(),
	type: SurveyQuestionTypeSchema,
	required: z.boolean().optional(),
	disabled: z.boolean().optional(),
	options: z.array(z.string()).optional(),
	style: z.any().optional(),
	priority: z.number(),
	validator: z.string().optional(),
	validatorMessage: z.string().optional(),
});

export const SurveySubmissionSchema = z.object({
	id: z.string(),
	uid: z.string(),
	surveyID: z.string(),
	phone: z.string().optional(),
	email: z.string().optional(),
	name: z.string().optional(),
	contactID: z.string().optional(),
	answers: z.array(z.object({})), // Placeholder for SurveyAnswer schema to avoid circular dependencies
	created: z.number().optional(),
});

export const SurveyAnswerValueTypeSchema = z.union([z.string(), z.number(), z.boolean(), z.array(z.string()), z.null(), z.undefined()]);

export const SurveyAnswerSchema = z.object({
	id: z.string(),
	questionID: z.string(),
	value: SurveyAnswerValueTypeSchema.optional(),
	type: SurveyQuestionTypeSchema,
	created: z.string().optional(),
});

export const SurveyThankYouActionSchema = z.object({
	showPopup: z.boolean().optional(),
	title: z.string().optional(),
	subTitle: z.string().optional(),
	redirectURL: z.string().optional(),
	popupDuration: z.number().optional(),
});

export const SurveyBackgroundTypeSchema = z.enum(['color', 'image']);

export const SurveyContainerStylesSchema = z.object({
	...ContainerStylesSchema.shape,
	backgroundType: SurveyBackgroundTypeSchema.optional(),
});

export const SurveyLogoOptionSchema = z.enum(['full-logo', 'avatar']);
export const SurveyContentAndDesignSchema = z.object({
	title: z.string().optional(),
	brandName: z.string().optional(),
	subTitle: z.string().optional(),
	description: z.string().optional(),
	consentText: z.string().optional(),
	buttonText: z.string().optional(),
	logoOption: SurveyLogoOptionSchema.optional(),
	enableLogo: z.boolean().optional(),
	enableCoverPhoto: z.boolean().optional(),
	enableBrandName: z.boolean().optional(),
	style: BrandStyleSchema.optional(),
	images: BrandLogosSchema.optional(),
	pageStyle: SurveyContainerStylesSchema.optional(),
	thankYouAction: SurveyThankYouActionSchema.optional(),
});

export const SurveySchema = z.object({
	id: z.string().optional(),
	uid: z.string(),
	name: z.string().optional(),
	description: z.string().optional(),
	redirectURL: z.string().optional(),
	type: SurveyTypeSchema,
	popupWidget: PixelPopupWidgetSchema.optional(),
	questions: z.array(SurveyQuestionSchema).optional(),
	settingsMap: z.record(z.number(), SurveyContentAndDesignSchema).optional(),
	allowAnon: z.boolean().optional(),
	archived: z.boolean(),
	startDate: z.string().optional(),
	endDate: z.string().optional(),
	locations: z.array(z.string()).optional(),
	created: z.string(),
	updated: z.string().optional(),
	config: z.any(),
	pendingInsights: z.boolean(),
});

export const SurveyCleanedSchema = SurveySchema.extend({
	visits: z.number().optional(),
	sends: z.number().optional(),
	lastSubmission: z.number().optional(),
	submissions: z.number().optional(),
});

export const SurveyAnswerInsightSchema = z.object({
	answer: z.string(),
	count: z.number(),
	percentage: z.number(),
});

export const SurveyQuestionInsightSchema = z.object({
	questionID: z.string(),
	totalCompletions: z.number(),
	lastSubmission: z.number(),
	insights: z.array(SurveyAnswerInsightSchema),
	created: z.number(),
});

export const SurveyAnswerWithPIISchema = z
	.object({
		contactID: z.string(),
		name: z.string(),
		phone: z.string(),
		email: z.string(),
		contactFavStoreName: z.string().optional(),
	})
	.merge(SurveyAnswerSchema);

export const SurveySendSchema = z.object({
	surveyID: z.string(),
	uid: z.string(),
	count: z.number(),
	ts: z.number(),
});

export const SurveySubmissionExtended = SurveySubmissionSchema.extend({
	surveyName: z.string().optional(),
	// Questions is a map of  ID -> Question
	questions: z.record(SurveyQuestionSchema).optional(),
});

export const SurveyWithInsightsSchema = z.object({
	survey: SurveySchema,
	insights: z.array(SurveyQuestionInsightSchema),
	visits: z.number(),
	sends: z.number(),
	submissions: z.number(),
});
