import { z } from 'zod';
import { CustomerAudienceSchema } from './contact';
import { AttributeSchema } from './inventory';
import { DiscountTemplateSchema } from './discount';
import { ReviewTemplateSchema } from './review';
import { MailingAddressSchema } from './geo';

export const SeedSchema = z.object({
	value: z.number(),
	previous: z.number(),
	updated: z.number(),
	duration: z.number(),
});

export const BrandProfileSchema = z.object({
	avatar: z.string().optional(),
	name: z.string().optional(),
	website: z.string().optional(),
	cover: z.string(),
	hidePointsInWallet: z.boolean(),
	enablePin: z.boolean(),
	premiumSupport: z.boolean(),
});

export const MediaObjectSchema = z.record(z.string(), z.any());

export const AdTemplateOutSchema = z.object({
	thirdParty: z.boolean(),
	clickthrough: z.string(),
	mediaURL: z.string(),
	mediaContentType: z.string(),
	media: MediaObjectSchema,
	learn: z.string(),
	shop: z.string(),
	largeBanner: z.boolean(),
	showButtons: z.boolean(),
	shopButtonText: z.string(),
	shopButtonStyle: z.string(),
	learnButtonText: z.string(),
	learnButtonStyle: z.string(),
	userID: z.string(),
	cmpID: z.string(),
	publisherUserID: z.string(),
	impressionTracker: z.string(),
	adSize: z.string(),
});

export const DynamicContentSchema = z.object({
	brand: z.string(),
	imageUrl: z.string(),
	name: z.string(),
	shopUrl: z.string(),
	price: z.number(),
});

export const SaleInsightsSchema = z.object({
	label: z.string(),
	count: z.number(),
});

export const WalletSchema = z.object({
	seed: SeedSchema.optional(),
	brandProfile: BrandProfileSchema,
	govtID: z.string(),
	email: z.string(),
	secondaryEmail: z.string(),
	homePhone: z.string(),
	mobilePhone: z.string(),
	lastName: z.string(),
	key: z.string(),
	favoriteStoreName: z.string(),
	firstName: z.string(),
	storeAddress: z.string(),
	storePhone: z.string(),
	storeShopURL: z.string(),
	customURL: z.string(),
	customURLAndroid: z.string(),
	customerType: z.number().nullable(),
	embeddedURL: z.string(),
	embeddedMedURL: z.string(),
	referLink: z.string(),
	contactID: z.string(),
	ad: AdTemplateOutSchema.optional(),
	reviewTemplates: z.array(ReviewTemplateSchema),
	discountTemplates: z.array(DiscountTemplateSchema),
	brandInsights: z.array(SaleInsightsSchema),
	categoryInsights: z.array(SaleInsightsSchema),
	productInsights: z.array(SaleInsightsSchema),
	audiences: z.array(CustomerAudienceSchema).optional(),
	customAttributes: z.array(AttributeSchema).optional(),
	topProducts: z.array(DynamicContentSchema).optional(),
	totalSales: z.number(),
	dob: z.number().optional(),
	addr: MailingAddressSchema.optional(),
	totalSpent: z.number(),
	rollingSpent: z.number(),
	rollingSales: z.number(),
	lastYearSpent: z.number(),
	lastYearSales: z.number(),
	avgGapDays: z.number(),
	lastSeen: z.number(),
	avgCartItems: z.number(),
	avgCheckout: z.number(),
	favoriteStoreID: z.number(),
	loyaltyPoints: z.number(),
	loyalty: z.boolean(),
	hasPassedAgeGate: z.boolean().optional(),
});
