import { z } from 'zod';

export const EventsSchema = z.object({
	uuid: z.string().optional(),
	key: z.string().optional(),
	data: z.string().optional(),
	timestamp: z.number(),
});

export const ViewEventSchema = z.object({
	label: z.string(),
	inserted: z.number(),
});
