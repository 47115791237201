import { z } from 'zod';

export const IncomingPhoneNumberSchema = z.object({
	region: z.string(),
	locality: z.string(),
	latitude: z.number(),
	longitude: z.number(),
	postal_code: z.string(),
	sid: z.string(),
	phone_number: z.string().optional(),
	area_code: z.string().optional(),
	friendly_name: z.string().optional(),
	sms_application_sid: z.string(),
	sms_method: z.string(),
	sms_url: z.string(),
	sms_fallback_method: z.string(),
	sms_fallback_url: z.string(),
	status_callback: z.string().optional(),
	status_callback_method: z.string().optional(),
	voice_application_sid: z.string(),
	voice_method: z.string().optional(),
	voice_url: z.string().optional(),
	voice_fallback_method: z.string().optional(),
	voice_fallback_url: z.string().optional(),
	voice_caller_id_lookup: z.boolean().nullable().optional(),
	emergency_status: z.string().optional(),
	emergency_status_sid: z.string().optional(),
	trunk_sid: z.string().optional(),
	identity_sid: z.string().optional(),
	address_sid: z.string().optional(),
	voice_receive_mode: z.string().optional(),
});
