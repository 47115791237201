import { z } from 'zod';

export const DiscountTemplateSchema = z.object({
	body: z.string(),
	userID: z.string(),
	name: z.string(),
	internalName: z.string(),
	userName: z.string(),
	disclaimer: z.string().optional(),
	storeShopURL: z.string(),
	storePhone: z.string(),
	lastRedemptionLocation: z.string(),
	contactKey: z.string(),
	favoriteStoreName: z.string(),
	avatar: z.string(),
	logo: z.string(),
	pageBody: z.string(),
	confirmText: z.string().optional(),
	priceCap: z.string(),
	barcodeValue: z.string(),
	posDiscountID: z.string(),
	barcodeType: z.string(),
	ecomCoupon: z.string(),
	id: z.string(),
	pageTitle: z.string(),
	storeIDs: z.array(z.string()),
	audienceIDs: z.array(z.string()),
	style: z.any().optional(),
	config: z.any().optional(),
	redemptions: z.array(z.any()),
	reusableDays: z.number(),
	reusableDaysUnit: z.number(),
	startTime: z.number(),
	relativeExpiration: z.number(),
	relativeExpirationUnit: z.number(),
	updated: z.number(),
	created: z.number(),
	pointsDeduction: z.number(),
	lastRedemptionTS: z.number(),
	percentageValue: z.number(),
	linkOpenCount: z.number(),
	dollarValue: z.number(),
	expiration: z.number(),
	redemptionCount: z.number(),
	addToPrizeWheel: z.boolean(),
	prizeTitle: z.string(),
	tierDiscount: z.boolean(),
	hasUsed: z.boolean(),
	min: z.boolean(),
	hidePointsInWallet: z.boolean(),
	reusable: z.boolean(),
	archived: z.boolean(),
	available: z.boolean(),
	hideRedeemInStore: z.boolean(),
	disableStacking: z.boolean(),
	orderPriority: z.number(),
	hasCouponCodes: z.boolean(),
	recipes: z.record(z.string(), z.boolean()).optional(),
	isLocked: z.boolean().optional(),
	lockedReason: z.string().optional(),
	redemptionType: z.string().optional(),
	redemptionMethod: z.string().optional(),
	couponType: z.string().optional(),
	uniqueCodeGenerationType: z.string().optional(),
	clonedFrom: z.string().optional(),
});

export type DiscountTemplate = z.infer<typeof DiscountTemplateSchema>;
