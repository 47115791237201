import { z } from 'zod';

export const CollectibleSchema = z.object({
	id: z.string(),
	srcID: z.string().optional(),
	url: z.string().optional(),
	productID: z.string(),
	batchID: z.string(),
	qr: z.string().optional(),
	rating: z.number().optional(),
	redeemedAt: z.number().optional(),
	redeemed: z.boolean().optional(),
	archived: z.boolean().optional(),
});
